import React from 'react';
import Button from "../components/Button/Button";
import Card from '../components/Card/Card';

import {images} from "../constants";

const About = () => {
  return (
    <>
      <div className='flex-col justify-between items-start lg:flex lg:flex-row lg:justify-between lg:items-center lg:gap-4 px-6 md:px-12 mt-6 mx-auto lg:px-24'>
        <div id='heading' className='text-[30px] md:text-[40px] font-black uppercase tracking-wide max-w-[85%] md:max-w-[70%] lg:max-w-[40%] pt-10'>
          <h1>modern digital <span className='text-primary'>design experience</span></h1>
        </div>
        <div>
        <Button 
            text="our services"
            link="/services"
          />
        </div>
      </div>
      <div className='flex flex-col md:flex-row md:justify-evenly md:items-center md:flex-wrap gap-1 w-full mt-4 justify-center items-center md:px-12'>
        <Card
          title="about us"
          link="/about"
          image={images.about}
        />
        <Card
          title="our approach"
          link="/services"
          image={images.contact}
        />
      </div>
    </>
  )
}

export default About;