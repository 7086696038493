import React from 'react';
import { MdArrowOutward } from "react-icons/md";
import { FaFacebook } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { CgWebsite } from "react-icons/cg";
import { RiAdvertisementFill } from "react-icons/ri";
import { FaRankingStar } from "react-icons/fa6";

import { NavLink } from 'react-router-dom';

const Hero = () => {
  return (
    <>
    <div className='flex items-center justify-between bg-hero h-contain w-[100%] bg-cover mt-2 z-2 gap-4 py-16 lg:py-40'>
        <div className='bg-transparent hidden lg:flex text-white lg:px-36'>
            <div className='bg-transparent flex flex-col justify-center items-center'>
                <a href='/'>
                    <div className='bg-transparent flex flex-row mb-[134px] items-center rotate-90 cursor-pointer'>
                        <div>
                        <FaFacebook 
                            className='mr-4'
                        />
                        </div>
                        <h2 className='uppercase font-bold text-md'>Facebook</h2>
                    </div>
                </a>
                <a href='/'>
                    <div className='bg-transparent flex flex-row mb-[134px] items-center rotate-90 cursor-pointer'>
                        <div>
                        <FaSquareXTwitter 
                            className='mr-4'
                        />
                        </div>
                        <h2 className='uppercase font-bold text-md'>Twitter</h2>
                    </div>
                </a>
                <a href='/'>
                    <div className='bg-transparent flex flex-row items-center rotate-90 cursor-pointer'>
                        <div>
                        <FaInstagram 
                            className='mr-4'
                        />
                        </div>
                        <h2 className='uppercase font-bold text-md'>Instagram</h2>
                    </div>
                </a>
            </div>
        </div>
        <div className='bg-transparent font-black text-[50px] md:text-[60px] uppercase flex flex-col flex-grow items-center justify-center'>
            <h1>Build Digital</h1>
            <h1 className='text-primary'>Product</h1>
            <h1>and brand</h1>
            <div className='bg-transparent flex justify-center items-center mt-6 text-primary w-full'>
                <NavLink to='/serivces'>
                    <MdArrowOutward 
                        className='bg-transparent flex justify-center items-center mb-6 text-primary w-[100px] h-[100px] cursor-pinter'
                    />
                </NavLink>
            </div>
        </div>
        <div className='bg-transparent hidden lg:flex text-white lg:px-36'>
            <div className='bg-transparent flex flex-col justify-center items-center'>
                <a href='/services'>
                    <div className='bg-transparent flex flex-row mb-[194px] items-center rotate-90 cursor-pointer'>
                        <div>
                        <CgWebsite 
                            className='mr-4'
                        />
                        </div>
                        <h2 className='uppercase font-bold text-md'>Web Development</h2>
                    </div>
                </a>
                <a href='/services'>
                    <div className='bg-transparent flex flex-row mb-[154px] items-center rotate-90 cursor-pointer'>
                        <div>
                            <RiAdvertisementFill 
                                className='mr-4'
                            />
                        </div>
                        <h2 className='uppercase font-bold text-md'>Advertisement</h2>
                    </div>
                </a>
                <a href='/services'>
                    <div className='bg-transparent flex flex-row items-center rotate-90 cursor-pointer'>
                        <div>
                        <FaRankingStar 
                            className='mr-4'
                        />
                        </div>
                        <h2 className='uppercase font-bold text-md'>SEO Ranking</h2>
                    </div>
                </a>
            </div>
        </div>
    </div>
    </>
  )
}

export default Hero;