
import { MdOutlineArrowOutward } from "react-icons/md";
import {FaGithub} from "react-icons/fa";

const ProjectDivision = ({
    heading,
    paragraph,
    year,
    role,
    githubLink,
    projectLink,
    tag,
    image,
    imageAlt
}) => {
  return (
    <div className='grid grid-cols-1 lg:grid-cols-2 w-full h-contain gap-12 mb-20'>
        <div className="w-[600px] h-[600px] bg-gray-800 rounded-lg relative flex items-center justify-center">
            <div id="pill" className="absolute top-4 rounded-full left-4 bg-black px-6 py-2 flex items-center justify-center">
                <p className="text-white text-[14px]">{tag}</p>
            </div>
            <div id="project__screenshot" className="absolute left-0 right-0 mx-auto w-[480px] h-[350px] rounded-xl">
            <img 
              src={image} 
              alt={imageAlt}
              className='rounded-lg object-contain w-[480px] h-[350px]'
            />
            </div>
        </div>
        <div className="flex flex-col justify-center">
            <div className="flex flex-col gap-4">
                <h1 className="text-[32px] font-medium">{heading}</h1>
                <p className="text-[18px] text-white/50 text-justify">{paragraph}</p>
            </div>
            <div className="flex flex-col gap-4 mt-10">
                <h1 className="uppercase text-[16px] font-semibold">project info</h1>
                <hr class="h-px bg-gray-700"/>
                <div className="flex justify-between items-center">
                    <p>Year</p>
                    <p>{year}</p>
                </div>
                <hr class="h-px bg-gray-700"/>
                <div className="flex justify-between items-center">
                    <p>Role</p>
                    <p>{role}</p>
                </div>
                <hr class="h-px bg-gray-700"/>
            </div>
            <div className="mt-12 flex gap-6">
                <a id="liveDemo" className="flex gap-2 items-center border-b-[2px] border-primary hover:border-pink-500 text-primary hover:text-pink-500 text-[16px]" href={projectLink}>
                    <p className="uppercase">view project</p>
                    <MdOutlineArrowOutward/>
                </a>
                <a id="github" className="flex gap-2 items-center border-b-[2px] border-primary hover:border-pink-500 text-primary hover:text-pink-500 text-[16px]" href={githubLink}>
                    <p className="uppercase">see on github</p>
                    <FaGithub/>
                </a>
            </div>
        </div>
    </div>
  )
}

export default ProjectDivision;