import React from 'react';
import {images} from "../constants";

const Footer = () => {
  return (
    <>
      <div className='mt-10 p-20 flex flex-col lg:flex-row justify-start lg:justify-between gap-16 bg-[#09090b]'>
          <div className='bg-transparent'>
            <a href='/' className='bg-transparent'>
              <img 
                src={images.logo} 
                alt='logo'
                loading='lazy'
                className='bg-transparent w-[60px] h-[60px] md:w-[80px] md:h-[80px] lg:w-[100px] lg:h-[100px] cursor-pointer'
              />
            </a>
            <p className='bg-transparent text-[#e2e8f0] mt-4 text-sm text-justify font-normal tracking-wide max-w-[450px]'>A Premier destination for integrated development and marketing services. With a steadfast commitment to excellence, Billmod have been at the forefront of the industry since our establishment in 2020. Our extensive portfolio boasts successful collaborations. By fostering close partnerships with our clients, we strive to deliver unparalleled development and marketing solutions, propelling businesses to new heights and establishing enduring relationships as trusted allies in their growth journey.</p>
          </div>
          <div className='bg-transparent text-white flex flex-col gap-6'>
            <h1 className='bg-transparent text-white text-xl font-bold tracking-wide'>Links</h1>
            <div className='bg-transparent text-white flex flex-col gap-2'>
              <a href='/' className='bg-transparent text-white uppercase hover:tracking-wider hover:text-primary hover:font-bold'>Home</a>
              <a href='/about' className='bg-transparent text-white uppercase hover:tracking-wider hover:text-primary hover:font-bold'>About Us</a>
              <a href='/contact' className='bg-transparent text-white uppercase hover:tracking-wider hover:text-primary hover:font-bold'>Contact Us</a>
              <a href='/services' className='bg-transparent text-white uppercase hover:tracking-wider hover:text-primary hover:font-bold'>Services</a>
              <a href='/team' className='bg-transparent text-white uppercase hover:tracking-wider hover:text-primary hover:font-bold'>Our Team</a>
            </div>
          </div>
          <div className='bg-transparent text-white flex flex-col gap-6'>
            <h1 className='bg-transparent text-white text-xl font-bold tracking-wide'>Contact Information</h1>
            <div className='bg-transparent flex flex-col gap-2'>
              <div className='bg-transparent flex flex-row items-center gap-4'>
                <div>
                  <lord-icon
                    src="https://cdn.lordicon.com/tdtlrbly.json"
                    trigger="loop"
                    delay="1000"
                    colors="primary:#000000,secondary:#25f2ff"
                    >
                  </lord-icon>
                  </div>
                  <p className='bg-transparent text-[#e2e8f0] text-md font-normal'>
                  71-75 Shelton Street, Covent Garden, London, United Kingdom, WC2H 9JQ
                  </p>
              </div>
              <div className='bg-transparent flex flex-row  items-center gap-4'>
                <div>
                  <lord-icon
                    src="https://cdn.lordicon.com/mhhpoybt.json"
                    trigger="loop"
                    delay="1000"
                    colors="primary:#121331,secondary:#faefd1,tertiary:#ebe6ef">
                  </lord-icon>
                  </div>
                  <p className='bg-transparent text-[#e2e8f0] text-md font-normal'>
                    +44 0208 0364978
                  </p>
              </div>
              <div className='bg-transparent flex flex-row items-center gap-4'>
                <div>
                <lord-icon
                  src="https://cdn.lordicon.com/aycieyht.json"
                  trigger="loop"
                  state="loop-flying"
                  colors="primary:#ffffff,secondary:#ffffff">
                </lord-icon>
                  </div>
                  <p className='bg-transparent text-[#e2e8f0] text-md font-normal'>
                    help@billmod.net
                  </p>
              </div>
            </div>
          </div>
      </div>

      {/* COPYRIGHT SECTION */}
      
      <div className='bg-[#09090b] border-t-2 border-white w-full flex items-center justify-center p-6'>
        <p className='bg-transparent text-white'>Copyright &copy; 2020 - 2024, <span className='bg-transparent text-primary'><a href='/'>Billmode</a></span> Pvt. LTD</p>
      </div>

    </>
  )
}

export default Footer;