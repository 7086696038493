import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const Button = ({text,link}) => {
  return (
    <NavLink to={link}>
      <div className='bg-primary text-[18px] font-bold uppercase text-black w-contain max-w-[60%] lg:max-w-none h-contain p-4 md:w-[240px] lg:w-[300px] lg:text-xl cursor-pointer flex items-center justify-center rounded-full mt-6 hover:bg-pink-500 hover:text-white transition-colors ease-in-out'>{text}</div>
    </NavLink>
  )
}

Button.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string
};

Button.defaultProps = {
  text: "Button",
  link: "/"
};

export default Button;