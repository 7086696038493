import React from 'react';
import Testimonial from "../../container/Testimonial";
import Team from "../../container/Team";
import { MdArrowOutward } from "react-icons/md";

import { LazyLoadComponent } from 'react-lazy-load-image-component';

const About = () => {

  const styles = {
    width: "30px",
    height: "30px"
  };

  const aboutStyles = {
    width: "350px",
    height: "350px"
  };


  return (
    <section className='px-16 lg:px-36 mt-20'>
      <div className='flex flex-col md:flex-row justify-center md:justify-between md:gap-8 lg:gap-12 items-center'>
        <div className=' mb-6 md:flex justify-center items-center md:w-[50%] z-[-10]'>
          <lord-icon
            src="https://cdn.lordicon.com/kiynvdns.json"
            trigger="loop"
            delay="1000"
            stroke="light"
            colors="primary:#ffffff,secondary:#25f2ff,tertiary:#ffc738,quaternary:#4bb3fd,quinary:#ebe6ef"
            style={aboutStyles}
          >
          </lord-icon>
        </div>
        <div className='flex flex-col justify-start items-center gap-4 flex-grow md:w-[50%]'>
          <h1 className='mt-6 md:mt-0 text-white text-center text-3xl uppercase font-black tracking-widest md:text-3xl'>Build your <span className='text-black bg-primary'>Brand</span> with Us</h1>
          <p className='text-white text-sm font-normal text-justify md:text-md'>Elevate your brand to new heights by choosing us as your dedicated partner in brand building. At <span className='text-primary uppercase font-bold'>Billmode</span>, we understand the nuanced art of crafting a compelling brand identity that resonates with your target audience. Our seasoned team of experts is committed to delivering bespoke solutions tailored to your unique brand vision. From strategic brand positioning to impactful visual design and consistent messaging, we are equipped to guide you through every facet of the brand-building process. With a proven track record of successful brand collaborations, we bring a wealth of experience across diverse industries, ensuring that your brand not only stands out but also establishes a lasting connection with your audience. By choosing <span className='text-primary uppercase font-bold'>Billmode</span>, you are investing in a partnership that values innovation, creativity, and a relentless pursuit of excellence, all aimed at propelling your brand to the forefront of your market. Let us be the catalyst for your brand's success, and together, we'll build a powerful and enduring brand legacy.</p>
        </div>
      </div>
      <div className='mt-20 flex flex-col md:flex-row justify-center items-center gap-6'>
        <div className='flex flex-col justify-center items-center gap-2 md:p-4'>
          <h1 className='font__bungee text-7xl text-primary'>45</h1>
          <h1 className='text-white text-xl uppercase font-bold tracking-wider text-center'><span className='underline decoration-primary hover:decoration-pink-500'>Projects</span> Completed</h1>
        </div>
        <div className='flex flex-col justify-center items-center gap-2 md:p-4'>
          <h1 className='font__bungee text-7xl text-primary'>30</h1>
          <h1 className='text-white text-xl uppercase font-bold tracking-wider text-center'>Happy <span className='underline decoration-primary hover:decoration-pink-500'>Customers</span></h1>
        </div>
        <div className='flex flex-col justify-center items-center gap-2 md:p-4'>
          <h1 className='font__bungee text-7xl text-primary'>08</h1>
          <h1 className='text-white text-xl uppercase font-bold tracking-wider text-center'>Years of <span className='underline decoration-primary hover:decoration-pink-500'>Experience</span></h1>
        </div>
      </div>
      <div className='mt-20 flex flex-col justify-center items-center'>
        <h1 className='text-white uppercase font-black tracking-widest text-4xl md:text-5xl lg:text-7xl text-center'>What we <span className='text-black bg-primary'>Offer</span> ?</h1>
        <div className='flex flex-col md:flex-row justify-center items-center mt-6 md:items-start md:px-6 lg:px-20' id='service__tag'>
          <div className='flex md:flex-col justify-between items-center md:items-start md:gap-2 gap-6 w-[40%]'>
            <h1 className='text-white font-black text-lg'>01</h1>
            <h1 className='text-white font-medium text-lg uppercase underline decoration-primary hover:decoration-pink-500 text-center'>Web development</h1>
          </div>
          <div className='flex flex-col justify-center items-start mt-4 gap-2 w-[70%]'>
            <p className='text-white text-sm text-justify'>Empower your digital presence with us – where <span className='text-primary uppercase'>innovation </span>meets seamless <span className='text-primary uppercase'>design</span>, and your website transforms into a dynamic gateway for success. Choose Billmode for web development that transcends boundaries and propels your brand into the digital spotlight</p>
            <h1 className='text-white text-sm font-semibold tracking-wider'>Our Skills in this sector:</h1>
            <div className='flex flex-row justify-between items-center gap-6'>
            <div>
              <lord-icon
                src="https://cdn.lordicon.com/qucadebu.json"
                trigger="loop"
                delay="1000"
                colors="primary:#25f2ff,secondary:#cb5eee"
                style={styles}>
              </lord-icon>
            </div>
            <p className='text-white text-md text-justify font-semibold'>Wordpress design and development</p>
            </div>
            <div className='flex flex-row justify-between items-center gap-6'>
            <lord-icon
              src="https://cdn.lordicon.com/qucadebu.json"
              trigger="loop"
              delay="1000"
              colors="primary:#25f2ff,secondary:#cb5eee"
              style={styles}
            >
            </lord-icon>
            <p className='text-white text-md text-justify font-semibold'>SEO implementation</p>
            </div>
            <div className='flex flex-row justify-between items-center gap-6'>
            <lord-icon
              src="https://cdn.lordicon.com/qucadebu.json"
              trigger="loop"
              delay="1000"
              colors="primary:#25f2ff,secondary:#cb5eee"
              style={styles}>
            </lord-icon>
            <p className='text-white text-md text-justify font-semibold'>3D implementation</p>
            </div>
            <div className='flex flex-row justify-between items-center gap-6'>
            <lord-icon
              src="https://cdn.lordicon.com/qucadebu.json"
              trigger="loop"
              delay="1000"
              colors="primary:#25f2ff,secondary:#cb5eee"
              style={styles}>
            </lord-icon>
            <p className='text-white text-md text-justify font-semibold'>Full Stack Development</p>
            </div>
          </div>
        </div>
        <div className='flex flex-col md:flex-row justify-center items-center mt-6 md:items-start lg:px-20 md:px-6' id='service__tag'>
          <div className='flex md:flex-col justify-between items-center md:items-start md:gap-2 gap-6 w-[40%]'>
            <h1 className='text-white font-black text-lg'>02</h1>
            <h1 className='text-white font-medium text-lg uppercase underline decoration-primary hover:decoration-pink-500 text-center'>Graphic Design</h1>
          </div>
          <div className='flex flex-col justify-center items-start mt-4 gap-2 w-[70%]'>
            <p className='text-white text-sm text-justify'>Revolutionize your visual identity with us – where <span className='text-primary uppercase'>creativity</span> meets <span className='text-primary uppercase'>precision</span>, and your graphics are not just designed; they're crafted for impact. Choose us for graphic design that transforms ideas into compelling visual stories, setting your brand apart in a world that demands brilliance</p>
            <h1 className='text-white text-sm font-semibold tracking-wider'>Our Skills in this sector:</h1>
            <div className='flex flex-row justify-between items-center gap-6'>
            <div>
              <lord-icon
                src="https://cdn.lordicon.com/qucadebu.json"
                trigger="loop"
                delay="1000"
                colors="primary:#25f2ff,secondary:#cb5eee"
                style={styles}>
              </lord-icon>
            </div>
            <p className='text-white text-md text-justify font-semibold'>Complete Brand Designing</p>
            </div>
            <div className='flex flex-row justify-between items-center gap-6'>
            <lord-icon
              src="https://cdn.lordicon.com/qucadebu.json"
              trigger="loop"
              delay="1000"
              colors="primary:#25f2ff,secondary:#cb5eee"
              style={styles}
            >
            </lord-icon>
            <p className='text-white text-md text-justify font-semibold'>Logo Designing</p>
            </div>
            <div className='flex flex-row justify-between items-center gap-6'>
            <lord-icon
              src="https://cdn.lordicon.com/qucadebu.json"
              trigger="loop"
              delay="1000"
              colors="primary:#25f2ff,secondary:#cb5eee"
              style={styles}>
            </lord-icon>
            <p className='text-white text-md text-justify font-semibold'>Ecommerce Products Designing</p>
            </div>
            <div className='flex flex-row justify-between items-center gap-6'>
            <lord-icon
              src="https://cdn.lordicon.com/qucadebu.json"
              trigger="loop"
              delay="1000"
              colors="primary:#25f2ff,secondary:#cb5eee"
              style={styles}>
            </lord-icon>
            <p className='text-white text-md text-justify font-semibold'>NFT Art Design</p>
            </div>
            <div className='flex flex-row justify-between items-center gap-6'>
            <lord-icon
              src="https://cdn.lordicon.com/qucadebu.json"
              trigger="loop"
              delay="1000"
              colors="primary:#25f2ff,secondary:#cb5eee"
              style={styles}>
            </lord-icon>
            <p className='text-white text-md text-justify font-semibold'>UI / UX Design</p>
            </div>
            <div className='flex flex-row justify-between items-center gap-6'>
            <lord-icon
              src="https://cdn.lordicon.com/qucadebu.json"
              trigger="loop"
              delay="1000"
              colors="primary:#25f2ff,secondary:#cb5eee"
              style={styles}>
            </lord-icon>
            <p className='text-white text-md text-justify font-semibold'>3D Model Development</p>
            </div>
          </div>
        </div>
        <div className='flex flex-col md:flex-row justify-center items-center mt-6 md:items-start lg:px-20 md:px-6' id='service__tag'>
          <div className='flex md:flex-col justify-between items-center md:items-start md:gap-2 gap-6 w-[40%]'>
            <h1 className='text-white font-black text-lg'>03</h1>
            <h1 className='text-white font-medium text-lg uppercase underline decoration-primary hover:decoration-pink-500 text-center'>Ecommerce Store</h1>
          </div>
          <div className='flex flex-col justify-center items-start mt-4 gap-2 w-[70%]'>
            <p className='text-white text-sm text-justify'>Elevate your e-commerce game with us – your one-stop solution for Amazon and Shopify store development. From <span className='text-primary uppercase'>pixel-perfect designs</span> to <span className='text-primary uppercase'>seamless user experiencesd</span>, trust us to turn your online vision into a revenue-generating reality. Choose success, choose us.</p>
            <h1 className='text-white text-sm font-semibold tracking-wider'>Our Skills in this sector:</h1>
            <div className='flex flex-row justify-between items-center gap-6'>
            <div>
              <lord-icon
                src="https://cdn.lordicon.com/qucadebu.json"
                trigger="loop"
                delay="1000"
                colors="primary:#25f2ff,secondary:#cb5eee"
                style={styles}>
              </lord-icon>
            </div>
            <p className='text-white text-md text-justify font-semibold'>Shopify design and development</p>
            </div>
            <div className='flex flex-row justify-between items-center gap-6'>
            <lord-icon
              src="https://cdn.lordicon.com/qucadebu.json"
              trigger="loop"
              delay="1000"
              colors="primary:#25f2ff,secondary:#cb5eee"
              style={styles}
            >
            </lord-icon>
            <p className='text-white text-md text-justify font-semibold'>Shopify Dropshipping Store</p>
            </div>
            <div className='flex flex-row justify-between items-center gap-6'>
            <lord-icon
              src="https://cdn.lordicon.com/qucadebu.json"
              trigger="loop"
              delay="1000"
              colors="primary:#25f2ff,secondary:#cb5eee"
              style={styles}>
            </lord-icon>
            <p className='text-white text-md text-justify font-semibold'>Amazon Store Handling</p>
            </div>
            <div className='flex flex-row justify-between items-center gap-6'>
            <lord-icon
              src="https://cdn.lordicon.com/qucadebu.json"
              trigger="loop"
              delay="1000"
              colors="primary:#25f2ff,secondary:#cb5eee"
              style={styles}>
            </lord-icon>
            <p className='text-white text-md text-justify font-semibold'>Amazon Products Listing</p>
            </div>
            <div className='flex flex-row justify-between items-center gap-6'>
            <lord-icon
              src="https://cdn.lordicon.com/qucadebu.json"
              trigger="loop"
              delay="1000"
              colors="primary:#25f2ff,secondary:#cb5eee"
              style={styles}>
            </lord-icon>
            <p className='text-white text-md text-justify font-semibold'>Amazon FBA</p>
            </div>
            <div className='flex flex-row justify-between items-center gap-6'>
            <lord-icon
              src="https://cdn.lordicon.com/qucadebu.json"
              trigger="loop"
              delay="1000"
              colors="primary:#25f2ff,secondary:#cb5eee"
              style={styles}>
            </lord-icon>
            <p className='text-white text-md text-justify font-semibold'>Amazon Virtaul Assistant (VA)</p>
            </div>
          </div>
        </div>
        <div className='flex flex-col md:flex-row justify-center items-center mt-6 md:items-start lg:px-20 md:px-6' id='service__tag'>
          <div className='flex md:flex-col justify-between items-center md:items-start md:gap-2 gap-6 w-[40%]'>
            <h1 className='text-white font-black text-lg'>04</h1>
            <h1 className='text-white font-medium text-lg uppercase underline decoration-primary hover:decoration-pink-500 text-center'>Social Media</h1>
          </div>
          <div className='flex flex-col justify-center items-start mt-4 gap-2 w-[70%]'>
            <p className='text-white text-sm text-justify'>Unleash the power of your brand on social media with us – where <span className='text-primary uppercase'>strategy</span> meets <span className='text-primary uppercase'>engagement</span>, and every campaign is a journey to digital triumph. Choose us for social media marketing that doesn't just make noise but orchestrates a symphony of success for your brand.</p>
            <h1 className='text-white text-sm font-semibold tracking-wider'>Our Skills in this sector:</h1>
            <div className='flex flex-row justify-between items-center gap-6'>
            <div>
              <lord-icon
                src="https://cdn.lordicon.com/qucadebu.json"
                trigger="loop"
                delay="1000"
                colors="primary:#25f2ff,secondary:#cb5eee"
                style={styles}>
              </lord-icon>
            </div>
            <p className='text-white text-md text-justify font-semibold'>Contect Creation and Curation</p>
            </div>
            <div className='flex flex-row justify-between items-center gap-6'>
            <lord-icon
              src="https://cdn.lordicon.com/qucadebu.json"
              trigger="loop"
              delay="1000"
              colors="primary:#25f2ff,secondary:#cb5eee"
              style={styles}
            >
            </lord-icon>
            <p className='text-white text-md text-justify font-semibold'>Audience Engagement</p>
            </div>
            <div className='flex flex-row justify-between items-center gap-6'>
            <lord-icon
              src="https://cdn.lordicon.com/qucadebu.json"
              trigger="loop"
              delay="1000"
              colors="primary:#25f2ff,secondary:#cb5eee"
              style={styles}>
            </lord-icon>
            <p className='text-white text-md text-justify font-semibold'>Social Media Advertising</p>
            </div>
            <div className='flex flex-row justify-between items-center gap-6'>
            <lord-icon
              src="https://cdn.lordicon.com/qucadebu.json"
              trigger="loop"
              delay="1000"
              colors="primary:#25f2ff,secondary:#cb5eee"
              style={styles}>
            </lord-icon>
            <p className='text-white text-md text-justify font-semibold'>Social Listening</p>
            </div>
            <div className='flex flex-row justify-between items-center gap-6'>
            <lord-icon
              src="https://cdn.lordicon.com/qucadebu.json"
              trigger="loop"
              delay="1000"
              colors="primary:#25f2ff,secondary:#cb5eee"
              style={styles}>
            </lord-icon>
            <p className='text-white text-md text-justify font-semibold'>Social Media Management</p>
            </div>
            <div className='flex flex-row justify-between items-center gap-6'>
            <lord-icon
              src="https://cdn.lordicon.com/qucadebu.json"
              trigger="loop"
              delay="1000"
              colors="primary:#25f2ff,secondary:#cb5eee"
              style={styles}>
            </lord-icon>
            <p className='text-white text-md text-justify font-semibold'>Influencer Marketing</p>
            </div>
          </div>
        </div>
      </div>
      <LazyLoadComponent>
        <Testimonial/>
      </LazyLoadComponent>
      <LazyLoadComponent>
        <Team/>
      </LazyLoadComponent>
      <div className='flex flex-col gap-4 items-center justify-center mt-20'>
        <h1 className='text-white text-4xl uppercase font-bold tracking-wider'>Get In Touch</h1>
        <a href='/contact' className='bg-transparent'>
          <MdArrowOutward 
            className='bg-transparent flex justify-center items-center mb-6 text-primary w-[80px] h-[80px] cursor-pinter'
          />
        </a>
      </div>
    </section>
  )
}

export default About;