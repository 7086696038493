import React from 'react';
import {feedback} from "../constants/testimonial";
import { useState } from 'react';
import { PiQuotesFill } from "react-icons/pi";
import { HiOutlineArrowSmallLeft,HiOutlineArrowSmallRight } from "react-icons/hi2";

import {images} from "../constants";



const Testimonial = () => {

  const [current, setCurrent] = useState(0);
  const length = feedback.length;

  const previous = () =>{
    setCurrent(current === 0 ? length - 1: current - 1);
  }

  const next = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  }

  return (

    <div className='bg-transparent'>
      {
        feedback.map((item,index) => index === current && <div key={index}>
          <div className='flex flex-col text-white items-center justify-center p-20 gap-10'>
            
            <div className='flex justify-center items-center'>
              <h1 className='text-white text-3xl font-bold tracking-wide uppercase md:text-5xl'>Feedback from Our Clients</h1>
            </div>
            <PiQuotesFill className="w-[42.6px] h-[27.6px] object-contain text-white bg-transparent md:w-[62.6px] md:h-[47.6px]"/>
            <h2 className='text-white text-md md:text-lg'>{item.content}</h2>
            <div className='flex-col lg:flex justify-between items-center gap-12 text-md md:text-lg'>
              <div className='flex gap-4 mb-12 lg:mb-0'>
                <img loading='lazy' className='w-[80px] h-[80px] md:w-[80px] md:h-[80px] rounded-full' src={images.customer} alt='client profile'/>
                <div className='flex flex-col justify-center items-start'>
                  <p className='text-white capitalize font-normal text-md'>{item.name}</p>
                  <p className='text-primary uppercase font-normal text-sm'>{item.title}</p>
                </div>
              </div>
              <div className='flex justify-between gap-4 items-center'>
                <div 
                  className='w-[40px] h-[40px] md:w-[60px] md:h-[60px] bg-primary rounded-lg cursor-pointer flex items-center justify-center'
                  onClick={previous}
                >
                  <HiOutlineArrowSmallLeft
                    className='bg-transparent text-black font-black text-xl md:text-3xl'
                  />
                </div>
                <div 
                  className='w-[40px] h-[40px] md:w-[60px] md:h-[60px] bg-primary rounded-lg cursor-pointer flex items-center justify-center'
                  onClick={next}
                >
                  <HiOutlineArrowSmallRight
                    className='bg-transparent text-black font-black text-xl md:text-3xl'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>)
      }
    </div>
  )
}

export default Testimonial;