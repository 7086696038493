import PropTypes from 'prop-types';
import { MdArrowOutward } from "react-icons/md";
import { NavLink } from 'react-router-dom';

const Card = ({title,link,image}) => {
  return (
    <NavLink to={link} className='text-lg hover:text-2xl md:max-w-[45%]'>
        <div class="relative mx-auto mt-10">
            <img loading='lazy' class="h-64 lg:h-[450px] w-full object-cover rounded-md" src={image} alt={title}/>
            <div class="absolute inset-0 bg-gray-700 opacity-60 rounded-md"></div>
            <div class="absolute bg-transparent inset-0 flex items-end justify-center">
                <div className='flex flex-row items-center justify-between bg-transparent'>
                    <h1 className='text-white uppercase font-semibold bg-transparent text-xl'>{title}</h1>
                    <div className='bg-transparent'>
                        <MdArrowOutward 
                                className='bg-transparent text-primary w-[60px] h-[60px] cursor-pinter'
                        />
                    </div>
                </div>
            </div>
         </div>
    </NavLink>
  )
}

Card.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.string
};

Card.defaultProps = {
  title: "Title",
  link: "/",
  image: "images/name"
};


export default Card;