import React, { useState } from 'react';
import { HiMenuAlt4, HiX } from 'react-icons/hi';

import { images } from '../../constants';

import { NavLink } from 'react-router-dom';

const Navbar = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);

    return (
        <>
        <nav className='flex justify-between items-center max-container relative z-30 py-5 px-8 lg:px-36 bg-dark'>
            <NavLink 
                to={"/"}
                className='cursor-pointer'
            >
                <img loading='lazy' src={images.logo2} alt='logo' className='w-[180px] h-[40px] md:w-[200px] md:h-[45px] b-none'/>
            </NavLink>

            <ul className='hidden h-full gap-12 lg:flex'>
                <li><NavLink className='uppercase text-white text-[16px] text-white-500 flex justify-center items-center capital cursor-pointer pb-1.5 transition-all hover:font-bold' to='/about'>About Us</NavLink></li>
                <li><NavLink className='uppercase text-white text-[16px] text-white-500 flex justify-center items-center cursor-pointer pb-1.5 transition-all hover:font-bold' to='/contact'>Contact Us</NavLink></li>
                <li><NavLink className='uppercase text-white text-[16px] text-white-500 flex justify-center items-center cursor-pointer pb-1.5 transition-all hover:font-bold' to='/services'>Services</NavLink></li>
                <li><NavLink className='uppercase text-white text-[16px] text-white-500 flex justify-center items-center cursor-pointer pb-1.5 transition-all hover:font-bold' to='/team'>Our Team</NavLink></li>
            </ul>

            <div className={isNavOpen ? "lg:hidden hidden": "lg:hidden block"}>
                <div className='flex lg:hidden jusitfy-center items-center border p-2 rounded-full bg-white-100'>
                    <HiMenuAlt4
                        className='text-white text-[25px] cursor-pointer'
                        onClick={()=> setIsNavOpen(!isNavOpen)}
                    />
                </div>
            </div>
            <div className={isNavOpen ? "lg:hidden block": "lg:hidden hidden"}>
                <div className='flex lg:hidden jusitfy-center items-center border p-2 rounded-full bg-white-100'>
                    <HiX
                        className='text-white text-[25px] cursor-pointer'
                        onClick={()=> setIsNavOpen(!isNavOpen)}
                    />
                </div>
            </div>
        </nav>
        <div className={isNavOpen ? "block": "hidden"}>
            <div className='bg-black absolute z-1 w-[100%] h-[80vh] flex justify-center items-center lg:hidden'>
                <ul className='flex flex-col'>
                <li><NavLink className='uppercase text-white regular-16 text-[18px] text-white-500 flex justify-center items-center cursor-pointer pb-1.5 transition-all hover:font-bold mb-5' to='/about'>About Us</NavLink></li>
                <li><NavLink className='uppercase text-white regular-16 text-[18px] text-white-500 flex justify-center items-center cursor-pointer pb-1.5 transition-all hover:font-bold mb-5' to='/contact'>Contact Us</NavLink></li>
                <li><NavLink className='uppercase text-white regular-16 text-[18px] text-white-500 flex justify-center items-center cursor-pointer pb-1.5 transition-all hover:font-bold mb-5' to='/services'>Services</NavLink></li>
                <li><NavLink className='uppercase text-white regular-16 text-[18px] text-white-500 flex justify-center items-center cursor-pointer pb-1.5 transition-all hover:font-bold mb-5' to='/team'>Our Team</NavLink></li>
                </ul>
            </div>
        </div>
        </>
    );
}
export default Navbar;