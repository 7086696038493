import React from 'react';
import { MdArrowOutward } from "react-icons/md";

const Services = () => {
  
  const styles = {
    width: "100px",
    height: "100px",
  };

  return (
    <section className='mt-20 px-16 lg:px-36'>
      <div className='flex-col justify-center items-center'>
        <h1 className='text-white text-3xl uppercase font-bold text-center'>Our <span className='text-black bg-primary'>Working</span> Process</h1>
        <div id='working__process' className='mt-6 h-contain flex flex-row justify-evenly items-center flex-wrap gap-8'>
          <div className='flex flex-col justify-center items-center gap-2 w-[200px]'>
            <div className='flex justify-between items-center gap-4 z-[-10]'>
              <h1 className='text-primary text-3xl font__bungee'>01</h1>
              <lord-icon
                src="https://cdn.lordicon.com/rfgxevig.json"
                trigger="loop"
                delay="2000"
                stroke="light"
                colors="primary:#e4e4e4,secondary:#25f2ff"
                style={styles}
                >
              </lord-icon>
            </div>
            <h1 className='text-white font-normal tracking-widest uppercase text-lg'>Consultation</h1>
            <p className='text-white text-sm font-normal text-center'>Gathering Customer Requirements and data</p>
          </div>
          <div className='flex flex-col justify-center items-center gap-4 w-[200px]'>
            <div className='flex justify-between items-center gap-6 z-[-10]'>
              <h1 className='text-primary text-3xl font__bungee'>02</h1>
              <lord-icon
                src="https://cdn.lordicon.com/vlycxjwx.json"
                trigger="loop"
                delay="2000"
                stroke="light"
                colors="primary:#e4e4e4,secondary:#25f2ff"
                style={styles}>
              </lord-icon>
            </div>
            <h1 className='text-white font-normal tracking-widest uppercase text-lg'>Crafting</h1>
            <p className='text-white text-sm font-normal text-center'>Developing responsive and visually appealing websites</p>
          </div>
          <div className='flex flex-col justify-center items-center gap-4 w-[200px]'>
            <div className='flex justify-between items-center gap-6 z-[-10]'>
              <h1 className='text-primary text-3xl font__bungee'>03</h1>
              <lord-icon
                src="https://cdn.lordicon.com/jdsvypqr.json"
                trigger="loop"
                delay="2000"
                colors="primary:#e4e4e4,secondary:#25f2ff"
                style={styles}>
              </lord-icon>
            </div>
            <h1 className='text-white font-normal tracking-widest uppercase text-lg'>Customization</h1>
            <p className='text-white text-sm font-normal text-center'>Implementing custom solutions and functionalities</p>
          </div>
          <div className='flex flex-col justify-center items-center gap-4 w-[200px]'>
            <div className='flex justify-between items-center gap-6 z-[-10]'>
              <h1 className='text-primary text-3xl font__bungee'>04</h1>
              <lord-icon
                src="https://cdn.lordicon.com/vcuhguff.json"
                trigger="loop"
                delay="2000"
                colors="primary:#25f2ff,secondary:#e4e4e4"
                style={styles}>
            </lord-icon>
            </div>
            <h1 className='text-white font-normal tracking-widest uppercase text-lg'>Consistency</h1>
            <p className='text-white text-sm font-normal text-center'>Ensuring optimal user experience and seamless navigation</p>
          </div>
        </div>
        <h1 className='mt-10 text-white text-3xl uppercase font-bold text-center'>Services <span className='text-black bg-primary'>Bilmode</span> Offer</h1>

          {/* WEB SERVICES */}

        <h1 className='mt-4 text-center text-white text-xl uppercase tracking-widest font-semibold '>Category : <span className='underline decoration-primary hover:decoration-pink-500'>Web</span></h1>
        <div className='mt-6 h-contain flex flex-row justify-evenly items-center flex-wrap gap-8'>
          <div className='flex flex-col w-[280px] h-contain'>
            <h1 className='text-primary uppercase text-3xl text-center font__bungee'>Development</h1>
            <p className='text-white font-normal text-sm text-justify'>Empower your brand with a digital presence that captivates and converts. Our web development expertise goes beyond mere coding – it's a strategic fusion of creativity, functionality, and user-centric design. Elevate your online identity with us, where every line of code is crafted to propel your business forward. Let's build a digital masterpiece together.</p>
          </div>
          <div className='flex flex-col w-[280px] h-conatin'>
            <h1 className='text-primary uppercase text-3xl text-center font__bungee'>DEFI</h1>
            <p className='text-white font-normal text-sm text-justify '>Step into the future of finance with our DeFi (Web3) development expertise. We're not just coding, we're crafting a decentralized ecosystem that empowers financial freedom. From smart contracts to decentralized applications (DApps), we bring your vision to life on the blockchain. Join us in redefining the landscape of finance, where innovation meets the limitless possibilities of decentralized technologies. Your journey to the decentralized future starts here.</p>
          </div>
          <div className='flex flex-col w-[280px] h-conatin'>
            <h1 className='text-primary uppercase text-3xl text-center font__bungee'>3D</h1>
            <p className='text-white font-normal text-sm text-justify '>Unleash the third dimension of digital innovation with our 3D-web development prowess. Elevate your online presence to new heights as we sculpt immersive and visually stunning experiences. From interactive 3D models to dynamic spatial design, we bring depth and engagement to your digital landscape. Join us in transcending the flat web into a three-dimensional realm where creativity knows no bounds. Let's turn your vision into a virtual reality that captivates and mesmerizes</p>
          </div>
          <div className='flex flex-col w-[280px] h-conatin'>
            <h1 className='text-primary uppercase text-3xl text-center font__bungee'>SEO</h1>
            <p className='text-white font-normal text-sm text-justify '>Amplify your digital footprint and conquer the online landscape with our SEO mastery. We don't just optimize websites; we engineer visibility, enhance rankings, and drive meaningful traffic to your doorstep. Partner with us to unlock the full potential of your online presence, where every click is a step towards dominating search engines and securing your spot at the forefront of your industry. Let's not just be seen; let's be unmissable in the digital realm</p>
          </div>

        </div>

          {/* GRAPHICs */}

          <h1 className='mt-10 text-center text-white text-xl uppercase tracking-widest font-semibold '>Category : <span className='underline decoration-primary hover:decoration-pink-500'>GRAPHICS</span></h1>
        <div className='mt-6 h-contain flex flex-row justify-evenly items-center flex-wrap gap-8'>
          <div className='flex flex-col w-[280px] h-conatin'>
            <h1 className='text-primary uppercase text-3xl text-center font__bungee'>web design</h1>
            <p className='text-white font-normal text-sm text-justify '>Transform your online vision into a visual masterpiece. Our web design expertise transcends aesthetics – it's about creating an immersive user experience that resonates with your audience. From sleek interfaces to seamless navigation, we craft designs that not only captivate but convert. Elevate your brand's digital identity with us, where innovation meets visual brilliance. Let's design the future of your online success together.</p>
          </div>
          <div className='flex flex-col w-[280px] h-conatin'>
            <h1 className='text-primary uppercase text-3xl text-center font__bungee'>web UI / UX</h1>
            <p className='text-white font-normal text-sm text-justify '>Crafting digital experiences that leave a lasting impression. Our UI/UX design expertise is the art of blending aesthetics with seamless functionality, ensuring your users not only engage but delight in every interaction. Elevate your brand's user journey with us, where intuitive design meets user-centric innovation. Let's shape a digital experience that goes beyond expectations, forging connections that last.</p>
          </div>
          <div className='flex flex-col w-[280px] h-conatin'>
            <h1 className='text-primary uppercase text-3xl text-center font__bungee'>brand designing</h1>
            <p className='text-white font-normal text-sm text-justify '>Transform your brand from ordinary to extraordinary with our visionary brand designing expertise. We don't just create logos; we craft identities that resonate, colors that speak, and a visual language that tells your unique story. Join us in shaping a brand that stands out in the crowded market, where every design element is meticulously curated to leave an indelible mark. Let's not just build a brand; let's craft a captivating narrative that echoes across every touchpoint</p>
          </div>
          <div className='flex flex-col w-[280px] h-conatin'>
            <h1 className='text-primary uppercase text-3xl text-center font__bungee'>nft art</h1>
            <p className='text-white font-normal text-sm text-justify '>Dive into the world of limitless creativity and exclusivity with our NFT art expertise. We don't just create digital assets; we transform art into unique, verifiable tokens on the blockchain. Elevate your artistic expression to the next level, where every piece becomes a rare and coveted masterpiece. Partner with us to navigate the intersection of art and technology, where your creativity is minted, owned, and celebrated in the exciting realm of NFTs. Let's turn your art into an unforgettable digital legacy.</p>
          </div>
        </div>

        {/* E-Commerce */}

        <h1 className='mt-10 text-center text-white text-xl uppercase tracking-widest font-semibold '>Category : <span className='underline decoration-primary hover:decoration-pink-500'>E-Commerce</span></h1>
        <div className='mt-6 h-contain flex flex-row justify-evenly items-center flex-wrap gap-8'>
          <div className='flex flex-col w-[280px] h-conatin'>
            <h1 className='text-primary uppercase text-3xl text-center font__bungee'>Wordpress</h1>
            <p className='text-white font-normal text-sm text-justify '>Empower your online presence with our WordPress design and development prowess. We don't just build websites; we craft dynamic, responsive, and visually stunning platforms that reflect your brand essence. Join us in transforming your digital space into a captivating experience, where WordPress expertise meets innovation. Let's navigate the vast potential of this versatile platform together, creating a seamless blend of design and functionality that not only speaks but engages. Elevate your online journey with our WordPress magic.</p>
          </div>
          <div className='flex flex-col w-[280px] h-conatin'>
            <h1 className='text-primary uppercase text-3xl text-center font__bungee'>shopify</h1>
            <p className='text-white font-normal text-sm text-justify '>Unleash the full potential of your e-commerce venture with our Shopify design and development mastery. We don't just create online stores; we engineer dynamic, user-friendly, and visually compelling Shopify experiences that drive sales. Join us in crafting a virtual storefront that not only showcases your products but elevates your brand. Let's navigate the e-commerce landscape together, where Shopify expertise meets seamless design, creating an irresistible online shopping experience for your customers. Elevate your business with our Shopify magic.</p>
          </div>
          <div className='flex flex-col w-[280px] h-conatin'>
            <h1 className='text-primary uppercase text-3xl text-center font__bungee'>amazon store</h1>
            <p className='text-white font-normal text-sm text-justify '>Ignite your e-commerce success on the world's largest marketplace with our Amazon store design and development expertise. We don't just create storefronts; we architect high-converting, visually appealing Amazon stores that stand out amidst the digital crowd. Partner with us to navigate the intricacies of the Amazon ecosystem, where our design meets strategy to elevate your brand and drive sales. Let's embark on a journey to make your products not just seen, but celebrated in the competitive world of Amazon. Elevate your Amazon presence with our strategic design touch.</p>
          </div>
          <div className='flex flex-col w-[280px] h-conatin'>
            <h1 className='text-primary uppercase text-3xl text-center font__bungee'>Dropshipping</h1>
            <p className='text-white font-normal text-sm text-justify '>Embark on a lucrative journey in e-commerce with our Shopify Dropshipping store design and development expertise. We're not just building stores; we're creating automated, visually compelling platforms that redefine the dropshipping experience. Join us in crafting a seamless and profitable online venture, where Shopify's power meets strategic design. Let's turn your dropshipping dreams into reality, ensuring your store not only looks stunning but operates efficiently. Elevate your e-commerce game with our expertise – where innovation meets hands-free business success.</p>
          </div>
        </div>

         {/* Social media */}

         <h1 className='mt-10 text-center text-white text-xl uppercase tracking-widest font-semibold '>Category : <span className='underline decoration-primary hover:decoration-pink-500'>Marketing</span></h1>
        <div className='mt-6 h-contain flex flex-row justify-evenly items-center flex-wrap gap-8'>
          <div className='flex flex-col w-[280px] h-conatin'>
            <h1 className='text-primary uppercase text-3xl text-center font__bungee'>SEO</h1>
            <p className='text-white font-normal text-sm text-justify '>Amplify your digital footprint and conquer the online landscape with our SEO mastery. We don't just optimize websites; we engineer visibility, enhance rankings, and drive meaningful traffic to your doorstep. Partner with us to unlock the full potential of your online presence, where every click is a step towards dominating search engines and securing your spot at the forefront of your industry. Let's not just be seen; let's be unmissable in the digital realm</p>
          </div>
          <div className='flex flex-col w-[280px] h-conatin'>
            <h1 className='text-primary uppercase text-3xl text-center font__bungee'>Social Marketing</h1>
            <p className='text-white font-normal text-sm text-justify '>Transform your brand's digital presence with our social media marketing mastery. We don't just create content; we orchestrate engaging narratives that resonate, captivate, and drive results. Join us in navigating the dynamic landscape of social media, where strategy meets creativity, and every campaign becomes a journey to digital triumph. Elevate your brand with our social media expertise – because it's not just about making noise; it's about creating a symphony of success in the ever-evolving world of social media.</p>
          </div>
          <div className='flex flex-col w-[280px] h-conatin'>
            <h1 className='text-primary uppercase text-3xl text-center font__bungee'>ad - campaigns</h1>
            <p className='text-white font-normal text-sm text-justify '>Supercharge your brand's visibility and engagement with our precision-crafted social media ad campaigns. We don't just create ads; we engineer strategies that cut through the digital noise, delivering your message directly to your target audience. Join us in transforming your social media presence into a dynamic marketing powerhouse, where every click is a step towards measurable success. Elevate your brand with our tailored ad campaigns – because it's not just about impressions; it's about making an impact that lasts.</p>
          </div>
          <div className='flex flex-col w-[280px] h-conatin'>
            <h1 className='text-primary uppercase text-3xl text-center font__bungee'>Email Marketing</h1>
            <p className='text-white font-normal text-sm text-justify '>Unlock the true potential of personalized communication with our email marketing expertise. We don't just send emails; we curate strategic campaigns that resonate, engage, and convert. Join us in leveraging the power of targeted messaging, where each email becomes a stepping stone towards cultivating lasting customer relationships. Elevate your brand with our email marketing prowess – because it's not just about the inbox; it's about crafting a narrative that inspires action and drives results.</p>
          </div>
        </div>
        <div className='flex flex-col gap-4 items-center justify-center mt-20'>
        <h1 className='text-white text-4xl uppercase font-bold tracking-wider'>Get In Touch</h1>
        <a href='/contact' className='bg-transparent'>
          <MdArrowOutward 
            className='bg-transparent flex justify-center items-center mb-6 text-primary w-[80px] h-[80px] cursor-pinter'
          />
        </a>
      </div>

      </div>
    </section>
  )
}

export default Services;