import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

import { FaMapLocation } from "react-icons/fa6";

const Contact = () => {

  const form = useRef();

  const sendEmail = (e) => {

    e.preventDefault();

    emailjs.sendForm(
      'service_3zs1pqk', 
      'template_xb8hhlq',
      form.current, 
      'DXRP_nd8JrgsQRn0N'
      )
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <section className='mt-20 px-16 lg:px-36'>

      <div className='flex flex-col justify-center items-center'>

        <h1 className='text-white text-3xl md:text-5xl uppercase font-black mb-2'>Have an Inquiry?</h1>

        <h1 className='bg-transparent text-primary text-3xl md:text-5xl uppercase font-black mb-4 font__bungee tracking-widest mb-2 lg:mr-8'>Contact Us</h1>

        <div className='flex flex-col justify-center items-center gap-8 lg:flex-row lg:justify-between lg:items-center'>

          <p className='text-white text-sm font-normal text-justify md:text-md lg:w-[50%]'>Embark on a transformative journey for your brand by reaching out to us today. At <span className='text-primary uppercase font-bold'>Billmode</span>, we're not just creators; <span className='text-primary uppercase font-bold'>we're architects of digital success</span>. Whether you're envisioning a stunning website, dynamic graphics, or a powerful social media campaign, our team is ready to turn your ideas into reality. With a commitment to innovation, creativity, and client satisfaction, we stand ready to be your strategic partner in achieving your business objectives. Contact us now to explore how we can elevate your brand, captivate your audience, and drive tangible results. Let's create something extraordinary together – because <span className='text-primary uppercase font-bold'>your success is our priority</span>.</p>

          <div id='contact__form' className='w-full mt-10 p-10'>

            <form id="contact-form" ref={form} onSubmit={sendEmail} className='text-white flex flex-col gap-4 items-between w-full text-sm md:text-md lg:text-lg'>
              <div className='flex flex-col justify-center items-center md:flex-row md:justify-between md:items-center md:gap-4 gap-4 w-full'>
                <div className="form-group flex justify-between items-center gap-6 w-full md:gap-0">
                  <label htmlFor="name"
                  className='mr-6'>
                    Name
                  </label>
                  <input type="text" name='from_name' className="bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-900 leading-tight focus:outline-none focus:bg-white focus:border-primary w-full" />
              </div>
              <div className="form-group flex justify-between items-center gap-6 w-full">
                  <label htmlFor="exampleInputEmail1" className='mr-6'>Email</label>
                  <input type="email" name='user_email' className="bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-900 leading-tight focus:outline-none focus:bg-white focus:border-primary w-full" aria-describedby="emailHelp" />
              </div>
            </div>
            <div className="form-group flex justify-between items-center gap-2">
                <label htmlFor="message" className='mr-6'>Message</label>
                <textarea name='message' className="bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-900 leading-tight focus:outline-none focus:bg-white focus:border-primary w-full" rows="5"></textarea>
            </div>
            <div className='w-full flex justify-center items-center'>
              <input type='submit' value="Send" className='bg-primary text-black py-2 px-4 rounded-2xl w-[40%] md:w-[30%] lg:w-[20%] font-bold uppercase tracking-wider text-md hover:bg-pink-500 hover:text-white cursor-pointer'/>
            </div>
          </form>
          </div>
        </div>
      </div>
      <div className='mt-10 flex flex-col justify-center items-center'>
        <h1 className='text-white text-3xl md:text-5xl uppercase font-black mb-2'>
          Need Consultation?
        </h1>
        <h1 className='bg-transparent text-primary text-3xl md:text-5xl uppercase font-black mb-4 font__bungee tracking-widest mb-2 lg:mr-8'>Visit Us At</h1>
        <div className='mt-6 flex justify-center items-center mb-4'>
          <div className='flex justify-between items-center gap-4'>
            <FaMapLocation 
              className='text-primary w-[30px] h-[30px]'
            />
            <h1 className='text-white font-bold tracking-wider text-lg uppercase'>London, United Kingdom</h1>
          </div>
        </div>
        <div className='flex flex-col items-center justify-center gap-4'>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9931.631918198362!2d-0.13386502780071652!3d51.51490419903736!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761b3354b746e3%3A0xac95f3aad95a14c5!2s1st%20Formations!5e0!3m2!1sen!2s!4v1707823148461!5m2!1sen!2s" allowfullscreen="false" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
          className=' w-[370px] h-[370px] md:w-[500px] md:h-[500px] lg:w-[750px] lg:h-[750px] rounded-xl shadow-2xl shadow-primary'
          title='Map Location'></iframe>
          <p className='text-white bg-transparent'>71-75 Shelton Street, Covent Garden, London, United Kingdom, WC2H 9JQ</p>
        </div>
        <div className=''></div>
      </div>
    </section>
  )
}

export default Contact;