import logo2 from "../assets/logo2.png";
import about from "../assets/about.jpg";
import contact from "../assets/contact.jpg";
import mission from "../assets/mission.jpg";

import build from '../assets/build.svg';

import client from "../assets/logo2.png";
import customer from "../assets/customer.jpg"
import hamza from "../assets/hamza.jpg";
import yameen from "../assets/Yameen.jpg";
import jahad from "../assets/Jahad.jpg";

import logo from "../assets/logo.png";

import FISLogo from "../assets/project/FIS/figs_logo.png";
import FIScard from "../assets/project/FIS/figs_card.png";
import FIStypo from "../assets/project/FIS/figs_typo.png";

import InnLogo from "../assets/project/Innovara/innovara_logo.png";
import OGNlogo from "../assets/project/OGN/ogn_logo.png";

import Game from "../assets/project/game_display.png";
import  carabina from "../assets/project/carabina_logo.png";

import coming from "../assets/coming_soon.png";
import Koko from "../assets/koko.jpg"

import tkrupt from "../assets/tkrupt.png";


export default {
    logo2,
    about,
    contact,
    mission,
    client,
    customer,
    build,
    hamza,
    yameen,
    jahad,
    logo,
    FISLogo,
    FIScard,
    FIStypo,
    InnLogo,
    OGNlogo,
    Game,
    carabina,
    coming,
    Koko,
    tkrupt
};