import PropTypes from 'prop-types';

import { AiFillInstagram } from "react-icons/ai";
import { FaFacebookF,FaXTwitter  } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const TeamCard = (
  { Name,
    Designation,
    instagramLink,
    linkedinLink,
    facebookLink,
    twitterLink,
    image
  }
  ) => {
  return (
    <div className="flex flex-col text-white grid min-h-[20rem] items-center overflow-hidden rounded-xl border-1 border-primary">
      <div className='flex flex-col justify-center items-center'>
        <LazyLoadImage
          src={image}
          effect='blur'
          alt={Name}
          className='rounded-full md:h-[15rem]'
        />
        <h4 className="bg-transparent block antialiased tracking-normal text-3xl font-bold leading-snug text-white text-center mt-4">{Name}</h4>
        <p className="bg-transparent block antialiased text-md md:text-lg font-bold leading-relaxed text-primary tracking-widest my-2 mb-3 uppercase text-center ">{Designation}</p>
        <div className='bg-transparent flex justify-evenly items-center gap-6 text-white text-xl' id='social_links'>
          <a href={instagramLink} className='bg-transparent cursor-pointer'>
              <AiFillInstagram 
                className='cursor-pointer bg-transparent '
              />
              </a>
              <a href={facebookLink} className='bg-transparent cursor-pointer'>
              <FaFacebookF 
                className='cursor-pointer bg-transparent'
              />
              </a>
              <a href={twitterLink} className='bg-transparent cursor-pointer'>
              <FaXTwitter 
                className='cursor-pointer bg-transparent'
              />
              </a>
              <a href={linkedinLink} className='bg-transparent cursor-pointer'>
              <FaLinkedinIn 
                className='cursor-pointer bg-transparent'
              />
              </a>
            </div>
      </div>
    </div>
  )
}

TeamCard.propTypes = {
  Name: PropTypes.string,
  Designation: PropTypes.string,
  instagramLink: PropTypes.string,
  linkedinLink: PropTypes.string,
  facebookLink: PropTypes.string,
  twitterLink: PropTypes.string,
  image: PropTypes.string
};

TeamCard.defaultProps = {
  Name: "Name",
  Designation: "Designation",
  instagramLink: "https://instagram.com/",
  linkedinLink: "https://linkedin.com/",
  facebookLink: "https://facebook.com/",
  twitterLink: "https://twitter.com/",
  image: "images/name"
};

export default TeamCard;