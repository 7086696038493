import React from 'react';
import Hero from '../../container/Hero';
import About from '../../container/About';
import Projects from '../../container/Projects';
import Team from "../../container/Team";
import Testimonial from '../../container/Testimonial';

const Home = () => {
  return (
    <div>
      <Hero/>
      <About/>
      <Projects/>
      <Team/>
      <Testimonial/>
    </div>
  )
}

export default Home;