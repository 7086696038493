import React from 'react';
import { MdArrowOutward } from "react-icons/md";
import Button from "../components/Button/Button";

const Projects = () => {
  return (
    <div className='bg-forproject mt-10 py-5 flex flex-col bg-cover'>
        <div className='px-6 md:px-12 lg:px-24'>
          {/* heading */}
        <div id='heading' className='flex flex-col md:flex-row md:gap-4 bg-transparent justify-center items-start md:mb-4'>
          <h1 className='text-[30px] md:text-[40px] font-black uppercase tracking-wide md:max-w-[70%] lg:max-w-[40%]'>Complete <span className='text-primary'>Projects</span></h1>
        </div>

        {/* heading */}
        <div className='w-full px-4 flex-col justify-center items-center lg:flex lg:flex-row lg:justify-between mt-8 bg-transparent'>
          <div className='flex-col justify-center items-center m-4 bg-transparent lg:w-[30em]'>
            <a href='/projects' className='cursor-pointer flex justify-between items-center mb-4 bg-transparent'>
              <h1 className='text-white text-xl font-bold uppercase'>Branding Design</h1>
              <div className='bg-transparent'>
                <MdArrowOutward 
                    className='bg-transparent text-primary w-[50px] h-[50px] cursor-pinter'
                  />
              </div>
            </a>
            <a href='/projects' className='cursor-pointer flex justify-between items-center mb-4 bg-transparent'>
              <h1 className='text-white text-xl font-bold uppercase'>digital art</h1>
              <div className='bg-transparent'>
                <MdArrowOutward 
                    className='bg-transparent text-primary w-[50px] h-[50px] cursor-pinter'
                  />
              </div>
            </a>
            <a href='/projects' className='cursor-pointer flex justify-between items-center mb-4 bg-transparent'>
              <h1 className='text-white text-xl font-bold uppercase'>nft design</h1>
              <div className='bg-transparent'>
                <MdArrowOutward 
                    className='bg-transparent text-primary w-[50px] h-[50px] cursor-pinter'
                  />
              </div>
            </a>
            <a href='/projects' className='cursor-pointer flex justify-between items-center mb-4 bg-transparent'>
              <h1 className='text-white text-xl font-bold uppercase'>web project</h1>
              <div className='bg-transparent'>
                <MdArrowOutward 
                    className='bg-transparent text-primary w-[50px] h-[50px] cursor-pinter'
                  />
              </div>
            </a>
            <a href='/projects' className='cursor-pointer flex justify-between items-center mb-4 bg-transparent'>
              <h1 className='text-white text-xl font-bold uppercase'>graphic design</h1>
              <div className='bg-transparent'>
                <MdArrowOutward 
                    className='bg-transparent text-primary w-[50px] h-[50px] cursor-pinter'
                  />
              </div>
            </a>
            <a href='/projects' className='cursor-pointer flex justify-between items-center bg-transparent'>
              <h1 className='text-white text-xl font-bold uppercase bg-transparent'>digital marketing</h1>
              <div className='bg-transparent'>
                <MdArrowOutward 
                    className='bg-transparent text-primary w-[50px] h-[50px] cursor-pinter'
                  />
              </div>
            </a>
          </div>
          <div className='flex-col justify-center items-center bg-transparent'>
            <p className='bg-transparent text-white text-md md:text-lg tracking-wide w-full lg:max-w-[40em] text-justify'>Established in 2020, Billmod emerged onto the global stage, securing our inaugural UK-based client within the first two years of our foundation. Recognizing the escalating demand for tailored software development and marketing strategies in Asian countries and the UK, we have strategically inaugurated our London office in 2024. Our primary objective has always been to elevate customer satisfaction and enhance our service delivery.Since our London expansion, we've had the privilege of collaborating with over 150 esteemed clients across the UK and Asian countries. Our clientele spans the spectrum, encompassing dynamic startups, publicly funded healthcare systems, and freelance projects. Below, we proudly present a curated glimpse into a selection of our past and present projects.</p>
            <Button
              text="Our Work "
              link="/projects"
            />
          </div>
        </div>
        </div>
    </div>
  )
}

export default Projects;