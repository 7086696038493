import './App.css';

import { Route, Routes } from 'react-router-dom';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import { images } from './constants';

//importing components

import Navbar from './components/Navbar/Navbar';
import Footer from "./container/Footer";

// importing different pages

import Home from './pages/Home/Home';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import Team from "./pages/Team/Team";
import Services from './pages/Services/Services';
import Yameen from "./pages/TeamDetails/Yameen";
import Hamza from "./pages/TeamDetails/Hamza";
import Jahad from "./pages/TeamDetails/Jahad";
import Phillipe from "./pages/TeamDetails/Philliple";

function App() {
  return(
    <div className='App'>
      <Navbar/>
      <Routes>
        <Route path='/' Component={Home}/>
        <Route path='/about' Component={About}/>
        <Route path='/contact' Component={Contact}/>
        <Route path='/services' Component={Services}/>
        <Route path='/team' Component={Team}/>
        <Route path='/yameen' Component={Yameen}/>
        <Route path='/hamza' Component={Hamza}/>
        <Route path='/jahad' Component={Jahad}/>
        <Route path='/Phillipe' Component={Phillipe}/>


      </Routes>
      <Footer/>
      <FloatingWhatsApp
        phoneNumber='+923270977690'
        accountName='Billmode'
        avatar={images.logo}
        statusMessage='Marketing & Development Agency'
        darkMode = 'true'
        chatMessage='Hello there! 🤝 \nHow can we help?'
        allowClickAway='true'
        allowEsc='true'
      />
    </div>
  )  
}

export default App;






