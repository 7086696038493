import { MdOutlineEmail, MdOutlineWhatsapp  } from "react-icons/md";
import { AiFillInstagram } from "react-icons/ai";
import { FaFacebookF,FaXTwitter  } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";

import {images} from "../../constants/index";

const Yameen = () => {
  return (
    <section className='mt-20 px-16 lg:px-20 flex flex-col lg:flex-row lg:max-h-[100vh] justify-between items-start gap-10 lg:gap-2'>
        <div id='sidebar__profile' className='min-h-[80vh] w-full rounded-xl lg:w-[25%] border-[1px] border-white pb-10'>
          <div className='flex flex-col items-center justify-between gap-2'>
            <img 
              src={images.yameen} 
              alt='yameen jilani'
              className='h-[30%] w-[30%] rounded-full mt-10'
            />
            <h1 className='text-white font-bold text-3xl text-center mt-2'>Yameen Jilani</h1>
            <p className='text-primary text-sm tracking-widest text-center uppercase'>Graphic Designer</p>
            <p className='text-white max-w-[80%] text-justify text-sm'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Laboriosam fugiat, doloribus est, illo esse minima nemo vel voluptate possimus quibusdam fugit quisquam eius quaerat incidunt?</p>
            <div className='bg-transparent flex justify-evenly items-center gap-6 text-white text-xl' id='social_links'>
          <a href='/' className='bg-transparent cursor-pointer'>
              <AiFillInstagram 
                className='cursor-pointer bg-transparent '
              />
              </a>
              <a href='/' className='bg-transparent cursor-pointer'>
              <FaFacebookF 
                className='cursor-pointer bg-transparent'
              />
              </a>
              <a href='/' className='bg-transparent cursor-pointer'>
              <FaXTwitter 
                className='cursor-pointer bg-transparent'
              />
              </a>
              <a href='/' className='bg-transparent cursor-pointer'>
              <FaLinkedinIn 
                className='cursor-pointer bg-transparent'
              />
              </a>
            </div>
            <div className='flex flex-col items-center mt-6 justify-between w-[80%] gap-2  border-slate-500 border-[1px] h-[20%] rounded-xl'>
              <div className='w-full py-2 px-6'>
                <h5 className='text-white'>Contact Yameen</h5>
              </div>
              <div className='flex flex-col justify-between items-center w-[90%]'>
                <div className='border-t-2 border-t-slate-700 py-4 w-full flex justify-start items-center gap-4'>
                  <MdOutlineEmail
                      className='text-primary h-[25px] w-[25px]'
                  />
                  <p className='text-white text-sm'>muhammadyameen906@gmail.com</p>
                </div>
                <div className='border-t-2 border-t-slate-700 py-4 w-full flex justify-start items-center gap-4'>
                  <MdOutlineWhatsapp
                      className='text-primary h-[25px] w-[25px]'
                  />
                  <p className='text-white text-sm'>+92 301 9702584</p>
                </div>
                <div className='flex justify-center items-center border-t-2 border-t-slate-700 py-4 w-full'>
                  <div className='bg-primary hover:bg-pink-500 hover:text-white p-2 px-6 rounded-xl w-[50%] cursor-pointer'>
                    <p className='text-center'>Services</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id='projects__section' className='w-[100%] lg:w-[70%] flex flex-col justify-center items-center'>
          <h1 className='text-primary text-center text-2xl font-bold uppercase tracking-wider'>Projects By Yameen Jilani</h1>
          <section id="yameen" className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 lg:gap-4 mt-6'>
            <iframe src="https://www.behance.net/embed/project/183104851?ilo0=1" height="280" width="360" allowfullscreen lazyload frameborder="0" allow="clipboard-write" refererPolicy="strict-origin-when-cross-origin"></iframe>
            <iframe src="https://www.behance.net/embed/project/164799589?ilo0=1" height="280" width="360" allowfullscreen lazyload frameborder="0" allow="clipboard-write" refererPolicy="strict-origin-when-cross-origin"></iframe>
            <iframe src="https://www.behance.net/embed/project/164165101?ilo0=1" height="280" width="360" allowfullscreen lazyload frameborder="0" allow="clipboard-write" refererPolicy="strict-origin-when-cross-origin"></iframe>
            <iframe src="https://www.behance.net/embed/project/161520213?ilo0=1" height="280" width="360" allowfullscreen lazyload frameborder="0" allow="clipboard-write" refererPolicy="strict-origin-when-cross-origin"></iframe>
            <iframe src="https://www.behance.net/embed/project/158977405?ilo0=1" height="280" width="360" allowfullscreen lazyload frameborder="0" allow="clipboard-write" refererPolicy="strict-origin-when-cross-origin"></iframe>
          </section>
        </div>
      </section>
  )
}

export default Yameen;