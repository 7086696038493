import React from 'react';

import { GoDotFill } from "react-icons/go";

import ProjectDivision from '../../components/ProjectDivision';

import {images} from "../../constants/index";
import { FaLinkedinIn, FaGithub  } from "react-icons/fa";

const Jahad = () => {

  const iconStyle = { width:"26px" , height:"26px" }

  return (
    <section>
      <section className='my-10 px-16 lg:px-20 grid grid-cols-1 md:grid-cols-2 gap-8'>
      <div id="info" className='flex flex-col items-start justify-center gap-4 p-8'>
        <h1 className='text-white font-bold text-7xl mt-2 uppercase'>Hi, I am<br/> Jahad Tariq.</h1>
        <p className='text-white/70 text-[18px]'>Experienced Frontend Web Developer with a focused expertise in React.js andproficiency in the MERN stack.</p>
        <div id='info__buttons' className='mt-6 flex flex-row justify-between items-center gap-4'>
          <div className='w-[180px] h-[54px] flex justify-between items-center bg-primary px-6 py-2 text-black font-semibold uppercase rounded-full'>
            <p>Contact Me</p>
            <div>
            <GoDotFill />
            </div>
          </div>
          <a className='w-[54px] h-[54px] p-4 rounded-full bg-primary/30 text-primary flex items-center justify-center cursor-pointer hover:bg-pink-500/30 hover:text-pink-500' href='/'>
            <FaLinkedinIn
              style={iconStyle}
            />
          </a>
          <a className='w-[54px] h-[54px] p-4 rounded-full bg-primary/30 text-primary flex items-center justify-center cursor-pointer hover:bg-pink-500/30 hover:text-pink-500' href='/'>
            <FaGithub 
              style={iconStyle}
            />
          </a>
        </div>
      </div>
      <div id="picture" className='object-contain'>
        <img 
              src={images.jahad} 
              alt='Muhammad Jahad Tariq'
              className='rounded-lg object-cover w-[600px] h-[600px]'
        />
      </div>
      </section>

      <hr class="h-px my-8 bg-gray-700"/>

      <section id='projects' className='mt-10 mb-20 px-16 lg:px-20'>
        <div className='py-20 flex flex-col items-start gap-4'>
          <h1 className='text-5xl uppercase font-bold'>featured products</h1>
          <p className='text-white/70 text-[18px] max-w-[600px]'>Here are some of the selected projects that showcase my passion for Web Development.</p>
        </div>

        <div className='grid grid-cols-1 w-full'>

          <ProjectDivision
            githubLink="/"
            projectLink="/"
            image={images.tkrupt}
            tag="Client Project"
            heading="Professional site for a software house."
            paragraph="Teamed up with a designer to breathe life into a promotional webpage for our beloved show, Adventure Time. Delivered a fully responsive design with dynamic content capabilities, seamlessly integrating a newsletter feature to keep fans updated with the latest adventures."
            year="2024"
            role="Front-End Developer"
          />

        </div>
      </section>

      <hr class="h-px my-8 bg-gray-700"/>

      <section id='aboutme' className='grid grid-cols-1 lg:grid-cols-2 gap-12 my-10 px-16 lg:px-20 pt-20'>
        <div className='flex items-start justify-center'>
          <h1 className='uppercase font-bold text-5xl'>About me</h1>
        </div>
        <div className='flex flex-col gap-4 items-start'>
          <h1 className='text-[32px] font-medium'>Experienced Frontend Web Developer with a focused expertise in React.js andproficiency in the MERN stack.</h1>
          <p className='text-[18px] text-white/50 text-justify'>With four years of freelance experience, I excel incrafting dynamic user interfaces that prioritize seamless user experiences. Acollaborative team player, I leverage my skills to contribute effectively to projectsuccess. Committed to staying updated on emerging technologies, I ensure projectsare delivered to the highest standards of performance and functionality, aligningclosely with employer needs.</p>
          <div className="mt-12 flex gap-6">
            <a id="liveDemo" className="flex gap-2 items-center border-b-[2px] border-primary hover:border-pink-500 text-primary hover:text-pink-500 text-[16px]" href="#projects">
              <p className="uppercase">View Projects</p>
            </a>
          </div>
        </div>
      </section>

    </section>
  )
}

export default Jahad;