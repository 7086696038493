import React from 'react';
import Button from "../components/Button/Button";
import TeamCard from "../components/TeamCard/TeamCard";

import {images} from "../constants/index";

const Team = () => {
  return (
    <section id='team' className='px-14 mt-12'>
        <div className='flex-col justify-between items-start lg:flex lg:flex-row lg:justify-around lg:gap-4'>
            <div id='heading' className='flex flex-col gap-2 bg-transparent justify-center items-start'>
                <h1 className='bg-transparent text-white text-3xl md:text-5xl uppercase font-black mb-1 tracking-widest'>
                    Meet  our  team  of
                </h1>
                <h1 className='bg-transparent text-white text-3xl md:text-5xl uppercase font-black mb-4 font__bungee tracking-widest'>
                    innovators
                </h1>
            </div>
            <div>
                <Button
                    text="Our team members"
                    link="/about"
                />
            </div>
        </div>
        <div className='mt-10 grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3 px-12 lg:px-36'>
            <a href='/Phillipe' className='fancy__cursor'>
            <TeamCard
                Name="Philippe Agbonson"
                Designation=" Influencer Manager & strategist"
                instagramLink="https://instagram.com"
                linkedinLink="https://instagram.com"
                facebookLink="https://instagram.com"
                twitterLink="https://instagram.com"
                image={images.Koko}
            />
            </a>
            <a href='/Jahad'>
            <TeamCard
                Name="Jahad Tariq"
                Designation="Web Developer"
                instagramLink="https://instagram.com"
                linkedinLink="https://instagram.com"
                facebookLink="https://instagram.com"
                twitterLink="https://instagram.com"
                image={images.jahad}
            />
            </a>
            <a href='/Hamza'>
            <TeamCard
                Name="Hamza Sultan"
                Designation="Wordpress / SEO"
                instagramLink="https://instagram.com"
                linkedinLink="https://instagram.com"
                facebookLink="https://instagram.com"
                twitterLink="https://instagram.com"
                image={images.hamza}
            />
            </a>
            <a href='/Yameen'>
            <TeamCard
                Name="Yameen Jilani"
                Designation="Graphic Designer"
                instagramLink="https://instagram.com"
                linkedinLink="https://instagram.com"
                facebookLink="https://instagram.com"
                twitterLink="https://instagram.com"
                image={images.yameen}
            />
            </a>
        </div>
    </section>
  )
}

export default Team;